import React from 'react';
import ReactDOM from 'react-dom';
import thunk from 'redux-thunk';
import App from './App';
import slotmachineReducer from './slotmachine/reducers/slotmachine';

import { Provider } from 'react-redux';
import { combineReducers, createStore, compose, applyMiddleware} from 'redux';
import Game from './slotmachine/components/game/Game';

const allReducers = combineReducers({
    slotmachineReducer
});

const middleware = [thunk];
const store = createStore(allReducers, compose(applyMiddleware(...middleware)));

ReactDOM.render(
  <Provider store={store}>
    <App store={store} />
  </Provider>,
  document.getElementById('root')
);

